import React from 'react';

const DiscordEmbed = () => {
  const serverId = '1100280821086158908';
  const width = '350';
  const height = '500';

  return (
    <iframe
      title="Discord Server"
      src={`https://discord.com/widget?id=${serverId}&theme=dark`}
      width={width}
      height={height}
      allowtransparency="true"
      frameborder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
    ></iframe>
  );
};

export default DiscordEmbed;
